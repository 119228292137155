import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from "@emotion/styled"

const Navigation = styled.nav`
li {
    display: inline-block;
    font-size: .8125rem;
    text-align: right;
    padding-left: 4vw;
    list-style: none;
    color: rgba(0,0,0,0.5);
    letter-spacing: 2px;
    transition: all 0.3s ease;
    &:first-of-type {
        margin-left: 0;
    }
}
a {
    text-decoration: none;
    color: rgba(0,0,0,0.5);
    transition: all 0.3s ease;
    &:hover {
        border-bottom: solid 1px #000;
        color: #000;
    }
}
a[aria-current='page'] {
    color: #000;
    border-bottom: solid 1px #000;
}

`

const Mainnav = () => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulSiteNav {
            nodes {
                id
                title
              pages {
                id
                slug
                title
              }
            }
          }
    }
    `)
    return (
        <Navigation>
            {data.allContentfulSiteNav.nodes.map(node => (
            <ul key={node.id}>
            {node.pages.map(page => (
                <li key={page.id}><Link to={`/${page.slug}`}>{page.title}</Link></li>
                ))}
            </ul>
            ))}
        </Navigation>
    )
}

export default Mainnav